import React from "react"
import InternalHeader from "../components/internalHeader"
import CaseStudyTileV2 from "../components/caseStudyTileV2"
import GetInTouch from "../components/getInTouch"
import styled from "styled-components"
import { Row } from "../utils/style-utils"
import { graphql } from "gatsby"
import GlobalComponents from "../components/globalcomponents"

const Blockquote = styled.blockquote`
  margin: 0 0 20px;
  color: ${(props) => props.theme.purple};
  font-weight: 500;
  font-size: 2.5rem;
  position: relative;
  padding: 0 15px;

  sup {
    font-size: 100%;
    color: ${(props) => props.theme["light-grey"]};
    top: 0px;

    &:first-child {
      left: 0;
      top: 12px;
      position: absolute;

      @media (min-width: 1024px) {
        top: 23px;
        left: 5px;
      }
    }
  }

  @media (min-width: 1024px) {
    font-size: 36px;
    padding: 0 30px;
    margin: 0 0 34px;
  }

  @media (min-width: 1600px) {
    font-size: 44px;
  }
`

const Block = styled.div`
  padding: 30px 20px 30px;
  margin: 0;

  @media (min-width: 1024px) {
    width: 66.6666%;
    padding-top: 96px;
    padding-bottom: 74px;
  }
`

const Author = styled.p`
  margin: 0;
  padding: 0 15px;

  strong {
    color: ${(props) => props.theme["dark-grey"]}
    font-weight: 500;
  }

  font-size: 14px;

  @media (min-width: 1024px) {
    font-size: 16px;
    padding: 0 30px;
  }
`

const WhoBlock = styled(Block)`
  @media (min-width: 1024px) {
    width: 83.6666%;
  }
`

const WhoIndexPage = ({ data }) => {
  return (
    <GlobalComponents>
      <InternalHeader
        title="Who we work with"
        description="“We consider our website to be at the forefront of user experience & design. We could not recommend Avenue enough – creative thinkers, client focused, strategic, innovative design, idea generation & delivery have all been top notch.”"
        flag="CLIENT TYPES"
        img="Avenue-Website-Header-083_nb1xzx"
        template="background"
      />

      <Row>
        <WhoBlock>
          <Blockquote>
            <sup>“</sup>
            We consider our website to be at the forefront of user experience &
            design. We could not recommend Avenue enough – creative thinkers,
            client focused, strategic, innovative design, idea generation &
            delivery have all been top notch.<sup>”</sup>
          </Blockquote>
          <Author>
            <strong>Lucy Ibrahim</strong> — (former) Head of Marketing, Visit
            Ballarat
          </Author>
        </WhoBlock>
      </Row>

      <section>
        <Row>
          {data?.allWpWho?.nodes.map((cs) => {
            return <CaseStudyTileV2 key={cs.uri} caseStudy={cs} />
          })}
        </Row>
      </section>
      <GetInTouch
        left="In coffee we trust."
        center="The best ideas start with great coffee. You pick the spot, we’ll pick up the tab."
        right="Let's have a chat"
        action="/contact"
      />
    </GlobalComponents>
  )
}

export default WhoIndexPage

export const query = graphql`
  query WhoQuery {
    allWpWho(sort: { order: DESC, fields: date }) {
      nodes {
        title
        uri
        excerpt
        featuredImage {
          node {
            sourceUrl
            srcSet
            mediaDetails {
              height
              width
            }
          }
        }
        date(formatString: "DD MMM YY")
      }
    }
  }
`
