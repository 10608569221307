import React from "react"
import { Link } from "gatsby"

import Img from "../components/responsiveCloudinary"
import HTMLReactParser from "html-react-parser"
import styles from "./caseStudyTile.module.scss"
import styled from "styled-components"
import { media } from "../utils/style-utils"
import AvenueImg from "../components/AvenueImg"

const Col = styled.div`
  flex: 0 0 auto;
  padding-left: 20px;
  padding-right: 20px;
  flex-basis: 100%;
  max-width: 100%;
  width: 100%;

  ${media.medium`
    flex-basis: 50%;
    max-width: 50%;
  `} ${media.large`
    flex-basis: 33.33333333%;
    max-width: 33.33333333%;
  `};
`

export default ({ caseStudy }) => {
  return (
    <Col>
      <Link
        className={`${styles.link} ${styles.block}`}
        key={caseStudy.uri}
        to={caseStudy.uri}
      >
        <div className={`${styles.thumbnail} ${styles.dataThumb}`}>
          <AvenueImg fluid={caseStudy.featuredImage?.node} />
          {/* <Img
            user="avenue"
            medium="50vw"
            large="440px"
            publicId={caseStudy.frontmatter.tileImage}
            alt=""
          /> */}
        </div>
        <h3 className={styles.title}>{caseStudy.title}</h3>
        <p className={styles.description}>
          {HTMLReactParser(caseStudy.excerpt)}
        </p>
      </Link>
    </Col>
  )
}
